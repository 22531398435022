import axios from 'axios'

import { auth_api } from './auth.api'
import { tripRequest_api } from './tripRequest.api'
import { trip_api } from './trip.api'
import { countries_api } from './countries.api'
import { cities_api } from './cities.api'
import { race_api } from './races.api'
import { users_api } from './users.api'
import { consultation_api } from './consultation.api'
import { news_api } from './news.api'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'const/token'

export const api = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const REQ = {
  auth_api,
  tripRequest_api,
  trip_api,
  countries_api,
  cities_api,
  race_api,
  users_api,
  consultation_api,
  news_api,
}

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(ACCESS_TOKEN)

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response) {
      const token = localStorage.getItem(ACCESS_TOKEN)

      if (
        (error.response.status === 401 || !token) &&
        window.location.pathname !== '/login'
      ) {
        // window.location.href = '/login'
        // localStorage.clear() // Redirect to /login from any page, but must be only when route /cabinet and !token
        return
      }


      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        window.location.pathname !== '/login'
      ) {
        originalRequest._retry = true
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_BASE_API_URL}/auth/refresh`,
          {
            refresh_token: localStorage.getItem(REFRESH_TOKEN),
          },
        )
        const { status, data } = response

        if (!status || !data) {
          throw new Error('No status code or data returned from server.')
        }

        if (status !== 200) {
          throw new Error(data)
        } else {
          return api(originalRequest)
        }
      }
    }

    return Promise.reject(error)
  },
)
