import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
import { userDto } from '@interfaces/dto/user.dto'
import { ACCESS_TOKEN } from 'const/token'

export interface IAuthSlice {
  user: userDto | null
  refresh: () => Promise<any>
  userUpdate: (user: Partial<userDto>) => Promise<any>
  changePhone: (phone: string) => Promise<any>
  logout: () => Promise<any>
  checkAuth: () => Promise<any>
  getUserInfo: () => Promise<any>
}

export const useAuthSlice: StateCreator<IAuthSlice> = (set) => ({
  user: null,
  refresh: async () => {
    const response = await REQ.auth_api.refresh()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      window.location.href = '/login'
      throw new Error(data)
    } else {
      set((state) => ({ ...state, user: data }))
      return response
    }
  },
  userUpdate: async (user) => {
    const response = await REQ.users_api.userUpdate(user)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, user: data }))
      return response
    }
  },
  logout: async () => {
    return new Promise((resolve, reject) => {
      localStorage.clear()
      resolve(true)

      if (localStorage.getItem(ACCESS_TOKEN)) {
        reject(new Error('Access token exists.'))
      }
    })
  },
  checkAuth: async () => {
    const response = await REQ.auth_api.protected()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    }
    return response
  },
  getUserInfo: async () => {
    const response = await REQ.users_api.getUserInfo()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, user: data.userInfo }))
      return response
    }
  },
  changePhone: async (phone) => {
    const response = await REQ.users_api.changePhone(phone)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        user: {
          ...state.user,
          phone,
        },
      }))
      return response
    }
  },
})
