/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAppStore } from '@store/useAppStore'

const AuthWrapper = ({ children }) => {
  const { checkAuth } = useAppStore()
  const router = useRouter()
  const { push, pathname } = router

  const inaccessRoutes = ['/cabinet', '/cabinet/*']

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (pathname !== '/login') {
      checkAuth()
        .then(() => {
          if (pathname === '/login') {
            push('/cabinet') //if user is logged in, redirect to cabinet
          } else if (pathname === '/cabinet' && !token) {
            push('/cabinet')
          }
        })
        .catch(() => {
          if (inaccessRoutes.includes(pathname)) {
            push('/login') //if user is not logged in, redirect to login
          }
        })
    }
  }, [pathname])

  return <>{children}</>
}

export default AuthWrapper
